import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';

dayjs.extend(weekOfYear);

const periodFieldMap = {
  Period: 'yearandweek',
  Monthly: 'yearandmonth',
  Quarterly: 'yearandquarter',
  Annual: 'year',
};
const dateFormatMap = {
  yearandweek: (date) => {
    const year = dayjs(date).format('YYYY');
    const week = dayjs(date).week();
    return year + week.toString().padStart(2, '0');
  },
  Monthly: (date) => dayjs(date).format('YYYYMM'),
  Quarterly: (date) => {
    const year = dayjs(date).format('YYYY');
    const month = dayjs(date).format('M');
    let quarter = '01';
    if (month >= 4 && month <= 6) {
      quarter = '02';
    } else if (month >= 7 && month <= 9) {
      quarter = '03';
    } else if (month >= 10 && month <= 12) {
      quarter = '04';
    }
    return year + quarter;
  },
  Annual: (date) => {
    const year = dayjs(date).format('YYYY');
    return year;
  },
};
const naturalFrequencyColumn = '"Natural_Frequency"';
export const createDateConditions = (dataset) => {
  let w = '';
  if (dataset.start_date) {
    w += ` AND "Date"::date >= '${dataset.start_date}'`;
  }
  if (dataset.end_date) {
    w += ` AND "Date"::date <= '${dataset.end_date}'`;
  }
  if (w) {
    w = ` WHERE 1=1 ${w} `;
  }
  return w;
};

export const createConditions = (dataset) => {
  let w = '';
  if (dataset.start_date) {
    w += ` AND "Date"::date >= '${dataset.start_date}'`;
  }
  if (dataset.end_date) {
    w += ` AND "Date"::date <= '${dataset.end_date}'`;
  }
  Object.entries(dataset.filters).forEach(([key, value]) => {
    if (value.length) {
      const inValue = value.join("','");
      w += ` AND ${key} IN ('${inValue}')`;
    }
    // console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
  });
  if (w) {
    w = ` WHERE 1=1 ${w} `;
  }
  return w;
};

export const createDatasetQuery = (dataset) => {
  const agg = dataset.for_multiple_values;
  const w = createConditions(dataset);
  // console.log('WHERE', w);
  // console.log('dataset', dataset);
  let sql = `select "Date", ${agg}(value) AS value from ${dataset.table_name} `;
  sql += w;
  sql += 'GROUP BY  "Date"';
  sql += 'ORDER BY  "Date" ASC';
  return sql;
};

export const createDatasetQueryParams = (dataset) => {
  const naturalFrequency = getNaturalFrequency(dataset);
  const joinColumn = periodFieldMap[naturalFrequency];
  const agg = dataset.for_multiple_values;
  const w = createConditions(dataset);
  // console.log('WHERE', w);
  // console.log('dataset', dataset);
  let sql = `select "${joinColumn}" AS "Date", ${agg}(value) AS value from ${dataset.table_name} `;
  sql += w;
  sql += `GROUP BY  "${joinColumn}"`;
  sql += `ORDER BY  "${joinColumn}" ASC`;
  return { sql, joinColumn };
};

export const createSeriesQuery = (dataset) => {
  const agg = dataset.for_multiple_values;
  const w = createConditions(dataset);
  let sql = `select "Location", "Date", ${agg}(value) AS value from ${dataset.table_name} `;
  sql += w;
  sql += 'GROUP BY  "Location", "Date"';
  sql += 'ORDER BY  "Location" ASC, "Date" ASC';
  return sql;
};

export const createWordcloudQueryParams = (dataset, value) => {
  const naturalFrequency = getNaturalFrequency(dataset);
  const joinColumn = periodFieldMap[naturalFrequency];
  const joinColumnValue = dateFormatMap[joinColumn](value);
  const sql = `SELECT *  FROM   ts_stat($$SELECT to_tsvector('ts.english_simple', narrative) FROM (select * from orr_daily_logs where ${joinColumn} = '${joinColumnValue}') a$$)  ORDER  BY ndoc desc LIMIT  1000`;
  return { sql, joinColumn };
};

export const createTrainedQueryParams = (model) => {
  const dataset = model.datasets.find((item) => item.axis === 'y');
  const tableName = `model_table_${model.model_code}`;
  const naturalFrequency = getNaturalFrequency(dataset);
  const joinColumn = periodFieldMap[naturalFrequency];
  const sql = `select * from ${tableName} LIMIT 10000`;
  return { sql, joinColumn };
};

export const createSeriesQueryParams = (dataset) => {
  const naturalFrequency = getNaturalFrequency(dataset);
  const joinColumn = periodFieldMap[naturalFrequency];

  const agg = dataset.for_multiple_values;
  const w = createConditions(dataset);
  let sql = `select "Location", ${joinColumn}, ${agg}(value) AS value from ${dataset.table_name} `;
  sql += w;
  sql += `GROUP BY  "Location",  ${joinColumn} `;
  sql += `ORDER BY  ${joinColumn} ASC`;
  return { sql, joinColumn };
};

export const createCategoryQueryParams = (dataset, column) => {
  const naturalFrequency = getNaturalFrequency(dataset);
  const joinColumn = periodFieldMap[naturalFrequency];

  const agg = dataset.for_multiple_values;
  const w = createConditions(dataset);
  let sql = `select ${column}, ${agg}(value) AS value from ${dataset.table_name} `;
  sql += w;
  sql += `GROUP BY ${column} `;
  sql += 'ORDER BY value ASC';
  return { sql, joinColumn };
};

export const createMapQueryParams = (dataset) => {
  const naturalFrequency = getNaturalFrequency(dataset);
  const joinColumn = periodFieldMap[naturalFrequency];

  const subQuery = createYQuery(dataset, 'y');

  const agg = dataset.for_multiple_values;
  // const w = createConditions(dataset);
  let sql = `select "Location", "Lat best", "Long best",  ${agg}(y) AS value from (${subQuery.sql}) s `;
  // sql += w;
  sql += 'GROUP BY  "Location", "Lat best", "Long best" ';
  sql += 'ORDER BY  "Location" ASC ';
  return { sql, joinColumn };
};

export const getLocationType = (dataset) => {
  try {
    return dataset.lists.location_type[0].value;
  } catch (e) {
    return null;
  }
};

export const createYDatasetQuery = (dataset) => {
  const agg = dataset.for_multiple_values;
  const w = createConditions(dataset);
  // console.log('WHERE', w);
  // console.log('dataset', dataset);
  let sql = `select "Date", "Location", "Location_Type", ${agg}(value) AS value from ${dataset.table_name} `;
  sql += w;
  sql += 'GROUP BY "Date", "Location", "Location_Type"';
  sql += 'ORDER BY "Date" ASC';
  return sql;
};

export const createLocationJoinQuery = (yDataset, xDataset) => {
  const sql = `select "${yDataset.lists.location_type[0].value}" as y_location, "${xDataset.lists.location_type[0].value}" as x_location from orr_stations_vs_everything osve group by  y_location, x_location`;
  return sql;
};

export const getFilteredNaturalFrequency = (dataset) => {
  let naturalFrequency = null;
  try {
    naturalFrequency = dataset.filters[naturalFrequencyColumn][0];
  } catch (error) {
    //
  }
  return naturalFrequency;
};

export const getFirstNaturalFrequency = (dataset) => {
  let naturalFrequency = null;
  try {
    naturalFrequency = dataset.lists.natural_frequency[0].value;
  } catch (error) {
    //
  }
  return naturalFrequency;
};

export const getNaturalFrequency = (dataset) => {
  let naturalFrequency = getFilteredNaturalFrequency(dataset);
  if (!naturalFrequency) {
    naturalFrequency = getFirstNaturalFrequency(dataset);
  }
  return naturalFrequency;
};

export const createYQuery = (dataset, alias) => {
  const naturalFrequency = getNaturalFrequency(dataset);
  const locationType = getLocationType(dataset);
  const joinColumn = periodFieldMap[naturalFrequency];
  const agg = dataset.for_multiple_values;
  const dateW = createDateConditions(dataset);
  const w = createConditions(dataset);

  let subSQL = `select y1.*, y1.full_${alias}, y2.${alias} from (select "Date", "Location", "Location_Type", to_char("Date"::date, 'DDD') as day_of_year, "year", yearandmonth, yearandweek, yearandquarter, ${agg}(value) as full_${alias} from ${dataset.table_name} ${dateW} group by "Date", "Location", "Location_Type", day_of_year, "year", yearandmonth, yearandweek, yearandquarter) as y1 `;
  subSQL += '\n';
  subSQL += `left join (select "Date", "Location", "Location_Type", to_char("Date"::date, 'DDD') as day_of_year, "year", yearandmonth, yearandweek, yearandquarter, ${agg}(value) as ${alias} from ${dataset.table_name} ${w} group by "Date", "Location", "Location_Type", day_of_year, "year", yearandmonth, yearandweek, yearandquarter) as y2 ON (y1."Date" = y2."Date" AND y1."Location" = y2."Location" AND y1."Location_Type" = y2."Location_Type")`;
  let sql = `(select * from (${subSQL}) y_sub) ${alias} `;
  sql += '\n';
  sql += `left join (select "${locationType}" as y_location, avg("Lat best") as "Lat best", avg("Long best") as "Long best" from orr_stations_vs_everything osve group by  y_location) as y_location_map on y_location_map.y_location = ${alias}."Location" `;
  sql += '\n';
  const result = {};
  result.natural_frequency = naturalFrequency;
  result.location_type = locationType;
  result.join_column = joinColumn;
  result.agg = agg;
  result.dataset = dataset;
  result.sql = sql;
  result.alias = alias;
  return result;
};

export const createTrainingTestingPredictionQuery = (model, location) => {
  const tableName = `model_table_${model.model_code}`;
  // const yDataset = model.datasets.find((item) => item.axis === 'y');
  const where = location ? ` WHERE "Location" = '${location}' ` : '';
  const predictionTableName = `model_table_${model.model_code}_predictions`;
  const sql = `SELECT "Date", row_type, sum(y) AS y, sum(y_pred) AS y_pred FROM ${tableName} ${where} GROUP BY "Date", row_type union select "Date", row_type, sum(y_pred) AS y, sum(y_pred) AS y_pred from ${predictionTableName} ${where} GROUP BY "Date", row_type`;
  return sql;
};

export const createPredictionQuery = (model, location) => {
  const tableName = `model_table_${model.model_code}_predictions`;
  const sql = `SELECT * FROM ${tableName}  where "Location" = '${location}' order by "Date" ASC`;
  return sql;
};

export const createXQuery = (dataset, alias) => {
  const naturalFrequency = getNaturalFrequency(dataset);
  const joinColumn = periodFieldMap[naturalFrequency];
  const locationType = getLocationType(dataset);

  const agg = dataset.for_multiple_values;
  const w = createConditions(dataset);

  let sql = `select "Location", ${joinColumn} as date_joiner, ${agg}(value) as ${alias} from ${dataset.table_name} `;
  sql += w;
  sql += `group by "Location", ${joinColumn}`;

  const result = {};
  result.natural_frequency = naturalFrequency;
  result.location_type = locationType;
  result.join_column = joinColumn;
  result.agg = agg;
  result.dataset = dataset;
  result.alias = alias;
  result.sql = sql;

  return result;
};

export const createTrainingQuery = (model) => {
  const result = prepareTrainingQuery(model);
  const fields = '"Date", "Location", "Location_Type", day_of_year, "year", yearandmonth, yearandweek, yearandquarter, "Lat best", "Long best"';
  let sql = `select ${fields}, ${result.xAgg} avg(y) AS y  from `;
  sql += '\n';
  sql += `(select ${result.y.alias}.*, y_location_map."Lat best", y_location_map."Long best"`;
  result.x.forEach((item) => {
    sql += `,${item.alias}.${item.alias}`;
  });
  sql += ' from' + '\n';
  sql += `${result.y.sql}`;
  const yAlias = result.y.alias;

  result.x.forEach((item) => {
    const locationQuery = createLocationJoinQuery(result.y.dataset, item.dataset);
    const xlTable = `${item.alias}_lm`;
    sql += '\n';
    sql += `left join (${locationQuery}) AS ${xlTable} ON ${xlTable}.y_location = ${yAlias}."Location"`;
    sql += '\n';
    sql += `left join (${item.sql}) AS ${item.alias} ON (${item.alias}."Location" = ${xlTable}.x_location AND ${item.alias}.date_joiner = ${yAlias}.${item.join_column})`;
  });
  sql += '\n';
  sql += ') f ';
  sql += '\n';
  sql += `GROUP BY ${fields}`;

  const query = {
    sql,
  };
  return query;
};

export const prepareTrainingQuery = (model) => {
  const yDataset = model.datasets.find((item) => item.axis === 'y');
  const xDatasets = model.datasets.filter((item) => item.axis === 'x');
  const yQuery = createYQuery(yDataset, 'y');
  const xQueries = [];
  let i = 0;
  let xAgg = '';
  xDatasets.forEach((dataset) => {
    i++;
    if (dataset.table_name) {
      dataset.start_date = yDataset.start_date;
      dataset.end_date = yDataset.end_date;
      const xQuery = createXQuery(dataset, `x${i}`);
      xAgg += `avg(x${i}) AS x${i}, `;
      xQueries.push(xQuery);
    }
  });
  const result = {
    y: yQuery,
    x: xQueries,
    xAgg,
  };
  return result;
  // let sql = `select f.* from () f
};
